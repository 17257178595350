export default [
  {
    name: "pierre",
    node: 4,
  },
  {
    name: "aline",
    node: 22,
  },
  {
    name: "joelle",
    node: 8,
  },
  {
    name: "fabio",
    node: 19,
  },
  {
    name: "rodolphe",
    node: 33,
  },
  {
    name: "laska",
    node: 999,
  },
];
