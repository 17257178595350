import * as THREE from "three";
import Vehicle from "../components/Vehicle";
import Node, { Adjacent } from "./Node";
import Segment from "./Segment";

export interface AdjacentRoad extends Adjacent {
  node: NodeRoad;
  path: Segment;
  direction: boolean | null;
}

export default class NodeRoad extends Node {
  adjacents: Array<AdjacentRoad>;
  previousTime: number = 0;
  speed: number = 0;
  currentSegments: Array<Segment>;
  length: number = 0;

  constructor(grid: any, datas: any) {
    super(grid, datas);

    this.adjacents = [];
    this.currentSegments = [];
  }

  run() {
    const length = this.adjacents.length;

    this.grid.map.game.addAnimation((time: number) => this.animate(time));
    if (length > 2) this.switchSegments();
    else this.currentSegments = this.adjacents.map((adjacent) => adjacent.path);
  }

  switchSegments() {
    let current = 0;
    const length = this.adjacents.length;

    const loop = () => {
      window.setTimeout(() => {
        this.currentSegments = [this.adjacents[current++].path];
        if (current === length) current = 0;

        loop();
      }, 3000);
    };

    loop();
  }

  getAdjacents(): Array<Adjacent> {
    return this.adjacents;
  }

  animate(time: number) {
    if (!this.previousTime) this.previousTime = time;
    const dt = time - this.previousTime;

    this.previousTime = time;

    return true;
  }
}
