import { DatasNode, DatasPath } from "../game.specs";
import Node from "./Node";
import search from "./search";
import Segment from "./Segment";
import Map from "../components/Map";

export default class Graph {
  map: Map;
  nodes: Array<Node>;

  nodesMap: any = {};

  size: any = {
    x: 0,
    y: 0,
  };
  offset: any | null = null;

  constructor(
    map: Map,
    datas: any = null,
    offset: any = null,
    size: any = null
  ) {
    this.map = map;
    this.offset = offset;
    this.size = size;
    this.nodes = this.loadNodes();
  }

  loadNodes(): Array<Node> {
    return [];
  }

  find(position: any): Node | null {
    throw new Error("Cannot find in path grid");
  }

  search(grid: any, start: any, end: any, filters: any) {
    this.nodes.forEach((node) => node.reset());
    return search(grid, start, end, filters);
  }
}
