import BinaryHeap from "./BinaryHeap";
import Node from "./Node";

// See list of heuristics: http://theory.stanford.edu/~amitp/GameProgramming/Heuristics.html

const heuristics = {
  manhattan: function (pos0: any, pos1: any) {
    var d1 = Math.abs(pos1.x - pos0.x);
    var d2 = Math.abs(pos1.y - pos0.y);
    var d3 = Math.abs(pos1.z - pos0.z);
    // return d1 + d2;
    return d1 + d2 + d3;
  },
  diagonal: function (pos0: any, pos1: any) {
    var D = 1;
    var D2 = Math.sqrt(2);
    var d1 = Math.abs(pos1.x - pos0.x);
    var d2 = Math.abs(pos1.y - pos0.y);
    return D * (d1 + d2) + (D2 - 2 * D) * Math.min(d1, d2);
  },
};

const pathTo = function (node: any) {
  var curr = node,
    path = [];
  while (curr.parent) {
    path.unshift(curr);
    curr = curr.parent;
  }
  //console.log(path);
  return path;
};

export default function search(
  graph: any,
  start: Node,
  end: Node,
  filter: (arg0: any) => any
) {
  var heuristic = heuristics.manhattan;
  // var heuristic = heuristics.diagonal;
  // var closest = false;

  var openHeap = new BinaryHeap(function (node: any) {
    return node.f;
  });
  var closest = start;

  start.h = heuristic(start.position, end.position);

  openHeap.push(start);

  while (openHeap.size() > 0) {
    var current = openHeap.pop();

    if (current === end) {
      return pathTo(current);
    }

    current.closed = true;

    var neighbors = current.getAdjacents();
    var neighbor, gScore, beenVisited;
    var len = neighbors.length;

    for (var i = 0, il = len; i < il; ++i) {
      neighbor = neighbors[i].node;

      if (neighbor.closed || filter(neighbor)) {
        continue;
      }

      gScore = current.g;
      beenVisited = neighbor.visited;

      if (!beenVisited || gScore < neighbor.g) {
        neighbor.visited = true;
        neighbor.parent = current;
        neighbor.h = neighbor.h || heuristic(neighbor.position, end.position);
        neighbor.g = gScore + 1;
        neighbor.f = neighbor.g + neighbor.h;

        if (closest) {
          if (
            neighbor.h < closest.h ||
            (neighbor.h === closest.h && neighbor.g < closest.g)
          ) {
            closest = neighbor;
          }
        }

        if (!beenVisited) {
          openHeap.push(neighbor);
        } else {
          openHeap.rescoreElement(neighbor);
        }
      }
    }
  }

  if (closest) {
    return pathTo(closest);
  }

  // No result was found - empty array signifies failure to find path.
  return [];
}
