import * as THREE from "three";

import Game from "./Game";
import Map from "./Map";
import Man from "./Man";
import Worker from "./Worker";
import View from "./View";
import workers from "../workers";

export default class Place {
  game: Game;
  map!: Map;
  mesh!: THREE.Mesh;
  man: Man | null;
  views: any;
  view!: View;

  constructor(game: Game) {
    this.game = game;
    this.man = null;
    this.views = {};
  }

  async select() {
    console.log("select place");

    const map = new Map(this.game);
    this.game.currentMap = map;
    this.map = map;

    await map.build();

    const background = map.scenes.background;

    const viewFront = new View(this);
    await viewFront.build(background, "front");
    this.views.front = viewFront;

    const viewSecret = new View(this);
    await viewSecret.build(background, "secret");
    this.views.secret = viewSecret;

    viewFront.show();
    this.view = viewFront;

    // map.grid.nodes.map((node) => map.game.addLabel(node.position, node.id));

    const objects = map.models;

    const ambient = new THREE.AmbientLight(0xffffff, 0.5);
    ambient.position.z += 5;
    background.add(ambient);
    background.add(objects.Sun);
    background.add(objects.Sun001);
    background.add(objects.Sun002);

    objects.Sun.intensity *= 0.15;
    objects.Sun001.intensity *= 0.15;
    objects.Sun002.intensity *= 0.15;

    const ground = objects.ground;

    // ground.material = new THREE.MeshPhongMaterial({
    //   color: 0xcccccc,
    // });
    ground.material = new THREE.ShadowMaterial();
    ground.material.opacity = 0.3;
    // ground.castShadow = true;
    ground.receiveShadow = true;

    ground.position.z += 0.01;
    background.add(ground);
    ground.userData = this;

    this.game.selectables.push(ground);

    // Object.values(objects).map((object: any) =>
    //   object.type === "DirectionalLight" ? (object.intensity *= 0.2) : null
    // );

    const light = objects.Sun;

    light.castShadow = true;
    light.shadow.bias = 0.00001;
    light.shadow.mapSize.width = 512;
    light.shadow.mapSize.height = 512;
    light.shadow.camera.near = 0.1;
    light.shadow.camera.far = 10;

    var size = 5;
    light.shadow.camera.top = size;
    light.shadow.camera.bottom = -size;
    light.shadow.camera.left = size;
    light.shadow.camera.right = -size;

    // var helper = new THREE.CameraHelper(light.shadow.camera);
    // foreground.add(helper);

    // var geometry = new THREE.BoxGeometry(0.2, 0.2, 0.2);
    // var material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    // var cube = new THREE.Mesh(geometry, material);

    // cube.castShadow = true;
    // cube.position.set(0.2, -1, 1);

    // foreground.add(cube);

    const man = new Man(this);
    await man.build(0, "tv");

    this.man = man;

    const scene = this.map.scenes.background;

    workers.map((datas: any) => {
      const worker = new Worker(this);
      worker.build(scene, datas.name);
    });
    // console.log(foreground);
    // man.runTo(4);
  }

  async setView(name: string) {
    return new Promise((resolve, reject) => {
      this.game.container.oldClassName = this.game.container.className;
      this.game.container.className += " switch-view";

      window.setTimeout(() => {
        for (var key in this.views) this.views[key].hide();
        this.view = this.views[name];
        this.views[name].show();
        this.game.container.className = this.game.container.oldClassName;

        resolve();
      }, 1000);
    });
  }

  goto(intersect: any) {
    const nodes = [
      { faces: [8, 9, 10, 11], node: 0 },
      { faces: [0, 1, 2, 3], node: 1 },
      { faces: [4, 5, 6, 7], node: 4 },
      { faces: [40, 41, 42, 43], node: 5 },
      { faces: [44, 45, 46, 47], node: 8 },
      { faces: [20, 21, 22, 23], node: 9 },
      { faces: [12, 13, 14, 15], node: 12 },
      { faces: [16, 17, 18, 19], node: 13 },
      { faces: [24, 25, 26, 27, 28, 29, 30, 31], node: 19 },
      { faces: [32, 33, 34, 35], node: 22 },
      { faces: [36, 37, 38, 39], node: 31 },
      { faces: [48, 49], node: 30 },
      { faces: [50, 51], node: 32 },
    ];

    let node: number | null = null;
    const faceIndex = intersect.faceIndex;

    for (let key = 0; key < nodes.length; key++) {
      if (nodes[key].faces.indexOf(faceIndex) !== -1) node = nodes[key].node;
    }

    console.log(faceIndex);

    if (this.man && node !== null) {
      this.man.runTo(node);
    }
  }
}
