import React, { useEffect, useRef, useState, useCallback } from "react";

import Game from "./components/Game.ts";
import "./app.scss";
import workers from "./comments";

const getMousePosition = (event) => {
  let position;

  if (event.changedTouches && event.changedTouches.length) {
    const touch = event.changedTouches[0];
    position = {
      x: touch.pageX,
      y: touch.pageY,
    };
  } else {
    position = {
      x: event.clientX,
      y: event.clientY,
    };
  }

  return position;
};

export default function App() {
  const container = useRef();
  const game = useRef();
  const music = useRef();
  const feet = useRef();
  const picture = useRef();

  const dev = process.env.NODE_ENV === "development";

  const [playMusic, setPlayMusic] = useState(!dev);
  const [play, setPlay] = useState(dev);
  const [ready, setReady] = useState(false);
  const [chat, setChat] = useState(null);
  const [chatIndex, setChatIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [comments, setComments] = useState([]);

  const setComment = useCallback(
    (comment) => {
      setComments((state) => [...state, comment]);

      const target = comment.target;

      if (target) {
        const nextComment = workers[chat].find(
          (comment) => comment.id === target
        );

        if (nextComment) {
          window.setTimeout(() => {
            if (nextComment.choices) setAnswers(nextComment.choices);
            else setComment(nextComment);
          }, 1000);
        }
      } else {
        window.setTimeout(
          () =>
            setAnswers([
              {
                content: "Terminé",
                end: true,
              },
            ]),
          1000
        );
      }
    },
    [chat]
  );

  const setAnswer = useCallback(
    (answer) => {
      setAnswers([]);

      if (answer.end) {
        setChat();
      } else {
        answer.player = true;
        setComment(answer);
      }
    },
    [setComment]
  );

  useEffect(() => {
    if (!chat) return;

    // console.log(chat, chatIndex, workers[chat]);
    const current = workers[chat][0];

    if (current) setComment(current);
  }, [chat, chatIndex, setComment]);

  useEffect(() => {
    if (!game.current) return;
    if (chat) return;

    game.current.closeChat();
    setChatIndex(0);
    setComments([]);
    setAnswers([]);
  }, [chat]);

  const onClick = useCallback(
    (event) => {
      if (!game.current) return;
      if (!ready) return;
      if (chat) return;

      // if (play) music.current.play();
      game.current.selectPlace(event);
    },
    [chat, ready]
  );

  useEffect(() => {
    if (!play) return;

    const newGame = new Game(
      {
        setReady,
        setChat,
      },
      picture.current,
      dev
    );
    newGame.init(container.current);
    newGame.setAudios({
      music: music.current,
      feet: feet.current,
    });
    music.current.volume = 0.5;
    // music.current.play();

    // music.current.muted = false;
    feet.current.muted = false;

    game.current = newGame;
  }, [dev, play]);

  useEffect(() => {
    if (!ready) return;

    music.current.currentTime = 0;
    if (playMusic) music.current.play();
    else music.current.pause();
  }, [playMusic, ready]);

  return (
    <div
      className={
        "app" +
        (play ? " play" : "") +
        (ready ? " ready" : "") +
        (chat ? " chatting" : "")
      }
      ref={container}
      // onMouseDown={onMouseDown}
      // onMouseMove={onMouseMove}
      // onMouseUp={onMouseUp}
      onClick={onClick}
      // onTouchMove={onMouseMove}
      // onTouchEnd={onMouseUp}
    >
      <audio controls ref={music} autostart="false">
        <source src="full_loose.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <audio controls ref={feet} autostart="false">
        <source src="feet.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <div
        className={"audio-controller " + (playMusic ? "play" : "")}
        onClick={() => setPlayMusic(!playMusic)}
      ></div>
      <div className="chat">
        <div className="close" onClick={() => setChat(false)}>
          ×
        </div>
        <div className="picture" ref={picture}></div>
        <svg width="100%" height="175" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <mask id="myMask">
              <rect
                width="100%"
                height="100%"
                y="50%"
                fill="white"
                rx="30"
                ry="30"
              />
              <circle cx="50%" cy="50%" r="75" fill="black" />
            </mask>
          </defs>

          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            fill="white"
            mask="url(#myMask)"
          />
        </svg>
        <div className="comments">
          {comments.map((comment, key) => (
            <div
              className={"message" + (comment.player ? " player" : " ")}
              key={key}
            >
              {comment.content}
            </div>
          ))}
          <div className="answers">
            {answers.map((answer, key) => (
              <div
                key={key}
                className="message"
                onClick={() => setAnswer(answer)}
              >
                {answer.content}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mask" />
      <div className="actions">
        <button onClick={() => setPlay(true)}>Jouer</button>
        <button onClick={() => (window.location = "https://lemon.ch")}>
          Ne pas jouer
        </button>
      </div>
    </div>
  );
}
