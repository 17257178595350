import * as THREE from "three";
import Place from "./Place";
import Node from "../astar/Node";
import NodeRoad from "../astar/NodeRoad";
import ScreenShader from "../shaders/ScreenShader";
import workers from "../workers";

export default class Man {
  place: Place;
  mesh!: THREE.Mesh;
  armature!: any;
  mixer!: THREE.AnimationMixer;
  clock = new THREE.Clock();
  node!: Node;
  event: any;
  animation: any;
  animations: any;
  eyeProperty: any;
  blinkProperty: any;
  mode: string = "wait";

  constructor(place: Place) {
    this.place = place;
    this.animations = [];
  }

  async build(node = 0, name: string) {
    const resource = this.place.game.resources.player;
    const file: any = resource.model;

    const mesh = file.objects[name];

    this.armature = file.objects[name + "armature"];

    this.place.map.scenes.background.add(this.armature);
    this.place.map.scenes.background.add(mesh);

    this.mesh = mesh;

    mesh.castShadow = true;
    mesh.children.map((child: THREE.Object3D) => (child.castShadow = true));
    // console.log(mesh);

    const texture = resource.texture;
    texture.flipY = false;

    mesh.children[0].material.map = texture;

    const screen = resource.eye;
    screen.flipY = false;
    screen.minFilter = THREE.LinearMipMapNearestFilter;

    const normals = this.place.game.resources.waterNormals;
    normals.wrapS = normals.wrapT = THREE.RepeatWrapping;

    const screenMaterial = new THREE.ShaderMaterial({
      ...ScreenShader,
      skinning: true,
      uniforms: {
        ...ScreenShader.uniforms,
        diffuse: { value: screen },
        diffuseColor: { value: new THREE.Color(0x9292ff) },
        // diffuseColor: { value: new THREE.Color(0xe1ff00) },
        normalSampler: {
          value: normals,
        },
      },
    });
    // const testMaterial = new THREE.MeshBasicMaterial({
    //   skinning: true,
    //   color: 0xff00ff,
    // });

    // mesh.children[1].material = testMaterial;
    mesh.children[1].material = screenMaterial;
    // mesh.children[1].material.map = screen;

    // const mixer = new THREE.AnimationMixer(file.gltf.scene);
    const mixer = new THREE.AnimationMixer(mesh.children[0]);
    // console.log(mixer);
    this.mixer = mixer;

    this.mixer.addEventListener("finished", (event: any) => {
      this.finishAction(event);
    });

    const animations = file.gltf.animations.reduce(
      (result: any, current: any) => {
        result[current.name] = { action: mixer.clipAction(current) };
        return result;
      },
      {}
    );

    // var cubeMaterial3 = new THREE.MeshPhongMaterial( { color: 0xccddff, envMap: textureCube, refractionRatio: 0.98, reflectivity: 0.9 } );

    this.animations = {
      wait: animations[name + ".wait"],
      walk: animations[name + ".walk"],
      slideStart: animations[name + ".slideStart"],
      slideEnd: animations[name + ".slideEnd"],
    };

    // console.log(this.animations);

    // baseActions.wait.action.play();
    // baseActions.walk.action.play();
    // baseActions.slide.action.play();

    // this.animations.wait.action.timeScale = 0.5;
    // baseActions.ArmatureAction.action.time = 3;
    // console.log(baseActions);
    this.node = this.place.map.grid.nodes[node];
    this.armature.position.copy(this.node.vector);

    const eye = this.animations.wait.action._propertyBindings.find(
      (property: any) => property.binding.path === "eye.quaternion"
    ).binding;

    const blink = this.animations.wait.action._propertyBindings.find(
      (property: any) => property.binding.path === "blink.quaternion"
    ).binding;

    let nextNoise = 2000 + Math.random() * 10000;

    this.animation = this.place.game.addAnimation(
      (time: number, dt: number) => {
        try {
          this.animate(time, dt);

          screenMaterial.uniforms.eye.value = eye.resolvedProperty;
          screenMaterial.uniforms.blink.value = blink.resolvedProperty;

          screenMaterial.uniforms.time.value = time / 1000;

          if (nextNoise < time) {
            nextNoise = time + 5000 + Math.random() * 5000;
            screenMaterial.uniforms.noiseScale.value = 0.05;

            window.setTimeout(
              () => (screenMaterial.uniforms.noiseScale.value = 0),
              100 + Math.random() * 500
            );
          }
          // console.log(blink.resolvedProperty);
        } catch (error) {
          console.log(error);
          return false;
        }

        return true;
      }
    );
  }

  runTo(target: number) {
    if (!this.node) {
      console.error("Unknown vehicle position");
      return;
    }

    // console.log("go to " + target);

    if (target === this.node.id) {
      console.log("Vehicle target identical to source");
      return;
    }
    if (this.event) {
      console.log("Vehicle event already attached");
      return;
    }

    const map = this.place.map;
    const grid = map.grid;

    var start = this.node;
    var end = map.grid.nodes.find((node: any) => node.id === target);
    const filters = () => false;

    // console.log(start, end);

    const nodes = map.grid.search(grid, start, end, filters);

    if (nodes.length)
      this.run([this.node, ...nodes], async () => {
        const worker = workers.find((worker) => worker.node === this.node.id);
        if (worker) {
          this.place.game.setChat(worker.name);
        } else if (this.node.id === 30) {
          this.animations.slideStart.action.setLoop(THREE.LoopOnce);
          this.animations.slideStart.action.reset().play();

          await this.place.setView("secret");

          this.node = this.place.map.grid.nodes[33];
          this.armature.position.copy(this.node.position);
          this.armature.rotation.z = 0;

          this.animations.slideStart.action.stop();
          this.animations.slideEnd.action.setLoop(THREE.LoopOnce);
          this.animations.slideEnd.action.reset().play();

          this.place.game.setChat("rodolphe");
        } else if (this.node.id === 32) {
          await this.place.setView("front");

          this.node = this.place.map.grid.nodes[29];
          this.armature.position.copy(this.node.position);
          this.armature.rotation.z = -Math.PI / 4;
        }
      });
    else console.log("Cannot find path");
  }

  finishAction(event: any) {
    if (event.action === "slideEnd") {
      // this.animations.slideEnd.stop();
      // this.animations.wait.start();
      this.animations.wait.start();
    }
  }

  run(nodes: Array<NodeRoad>, callback: Function) {
    const nodesLength = nodes.length;
    const steps = [];
    const speed = 0.00085;

    // console.log("Run", nodes);

    for (let key = 0; key < nodesLength - 1; key++) {
      const move = nodes[key + 1].vector.clone().sub(nodes[key].vector);

      const duration = move.length() / speed;

      const adjacent = nodes[key].adjacents.find(
        (adjacent: any) => adjacent.node.id === nodes[key + 1].id
      );

      if (!adjacent) {
        throw new Error("Cannot find adjacent node");
      }

      const path = this.place.map.grid.paths.find(
        (path: any) => path.id === adjacent.path
      );

      if (!adjacent) {
        throw new Error("Cannot find adjacent path");
      }

      steps.push({
        source: nodes[key],
        target: nodes[key + 1],
        path,
        move: move.normalize().multiplyScalar(speed),
        duration: duration,
        direction: adjacent.direction,
      });
    }

    // console.log(steps);

    this.event = {
      startTime: undefined, //new Date().getTime(),
      steps,
      callback,
      // direction,
      // distance,
      // speed,
    };
  }

  animate(time: number, dt: number) {
    // this.mesh.rotation.y += 0.01;

    if (this.event) {
      if (this.event.startTime === undefined) this.event.startTime = time;

      const steps = this.event.steps;
      const duration = time - this.event.startTime;
      let stepDuration = duration;
      let key = 0;
      let currentDuration = 0;

      // return;

      do {
        currentDuration += steps[key].duration;
        stepDuration -= steps[key].duration;

        key++;
      } while (currentDuration < duration && key < steps.length);

      key -= 1;
      const step = steps[key];

      stepDuration += step.duration;

      const position = step.source.position;

      this.armature.position.copy(
        position.clone().add(step.move.clone().multiplyScalar(stepDuration))
      );
      const currentRotation = this.armature.rotation.z;
      let deltaRotation =
        Math.atan2(step.move.y, step.move.x) - currentRotation;

      while (Math.abs(deltaRotation) > Math.PI)
        deltaRotation -= Math.PI * 2 * Math.sign(deltaRotation);

      const rotation = (deltaRotation + deltaRotation) * dt * 0.0045;

      this.armature.rotation.z =
        currentRotation +
        (Math.abs(rotation) > Math.abs(deltaRotation)
          ? deltaRotation
          : rotation);
      // Math.min(Math.abs(deltaRotation), 0.05) * Math.sign(deltaRotation);

      if (key === steps.length - 1 && currentDuration < duration) {
        this.node = steps[key].target;
        const callback = this.event.callback;

        this.event = null;

        callback();
      }
    }

    const audio = this.place.game.audios.feet;

    const wait = this.animations.wait.action;
    const walk = this.animations.walk.action;

    // if (this.event && this.mode !== "walk") {
    if (this.event) {
      wait.stop();
      walk.play();

      audio.play();

      // this.mode = "walk";
    } else {
      // } else if (this.mode === "walk") {
      walk.stop();
      wait.play();

      audio.pause();
      audio.currentTime = 0;

      this.mode = "wait";
    }

    // console.log(this.test.binding.resolvedProperty);

    var mixerUpdateDelta = this.clock.getDelta();
    // console.log(mixerUpdateDelta, time);
    this.mixer.update(mixerUpdateDelta);
    // console.log(mixerUpdateDelta);

    return true;
  }
}
