import * as THREE from "three";
import { DatasPosition } from "../game.specs";
import Graph from "./Graph";

export interface Adjacent {
  node: Node;
  direction: boolean | null;
}

export default class Node {
  grid: Graph;
  id: number;
  position: THREE.Vector3;
  g: number = 0;
  h: number = 0;
  f: number = 0;
  parent: null;
  visited: boolean = false;
  closed: boolean = false;
  vector: THREE.Vector3;
  distance: number = 0;
  status: number = 0;

  constructor(grid: any, datas: any) {
    this.grid = grid;
    this.id = datas.id;
    this.position = new THREE.Vector3(
      datas.position.x,
      datas.position.y,
      datas.position.z
    );
    this.vector = new THREE.Vector3(
      this.position.x,
      this.position.y,
      this.position.z
    );
  }

  reset() {
    this.g = this.h = this.f = 0;
    this.visited = this.closed = false;
    this.parent = null;
  }

  getAdjacents(): Array<Adjacent> {
    return [];
  }

  toJSON() {
    return {
      id: this.id,
      position: this.position,
    };
  }
}
