import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import Place from "./Place";
import Vehicle from "./Vehicle";
import Map from "./Map";
import { DOMElement } from "react";
import Worker from "./Worker";
import workers from "../workers";
// import GameMap from "./GameMap";
var OrbitControls = require("three-orbit-controls")(THREE);

export default class Game {
  animations: Array<any>;
  size: any = {
    x: 0,
    y: 0,
  };
  selectables: Array<any> = [];
  renderer!: THREE.WebGLRenderer;
  // map!: GameMap;
  currentMap!: Map;
  container: any;
  audios: any;
  place!: Place;
  resources: any;
  dev: boolean = false;
  startTime: number = 0;
  resourcesCount: number = 0;
  resourcesLoaded: number = 0;
  setters: any;
  chat: THREE.Scene | null;
  chatContainer: any;
  chatCamera!: THREE.PerspectiveCamera;
  chatSize: any = {
    x: 150,
    y: 200,
  };

  chatPosition: any = {
    x: 0,
    y: 0,
  };

  chatWorker: Worker | null;

  constructor(setters: any, chatContainer: any, dev: boolean = true) {
    this.animations = [];
    this.container = null;
    this.audios = [];
    this.dev = dev;
    this.setters = setters;
    this.chat = null;
    this.chatContainer = chatContainer;
    this.chatWorker = null;
  }

  async init(container: any) {
    const box = container.getBoundingClientRect();
    this.container = container;

    this.size = {
      x: box.width,
      y: box.height,
    };

    this.onWindowResize();

    var renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true,
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    // renderer.setPixelRatio(1);
    renderer.setSize(this.size.x, this.size.y);
    // renderer.setClearColor(0x000000, 0);
    // renderer.setClearColor(0xffffff, 0);
    // renderer.setClearColorHex ( 0xFFFFFF, 0.0 );
    renderer.autoClear = false;
    // renderer.colorManagement = true;
    // renderer.gammaOutput = true;
    // renderer.gammaFactor = 1.3;
    // renderer.gammaFactor = 2.0;
    // renderer.gammaFactor = 2.2;
    renderer.gammaFactor = 2.4;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    // renderer.physicallyCorrectLights = true;

    this.renderer = renderer;

    container.appendChild(renderer.domElement);

    this.startTime = new Date().getTime();

    this.loadResources();

    // window.addEventListener("resize", () => this.resize());
  }

  async run() {
    const place = new Place(this);
    this.place = place;

    await place.select();

    window.addEventListener("resize", () => this.onWindowResize(), false);

    const diffTime = new Date().getTime() - this.startTime;

    window.setTimeout(() => {
      window.setTimeout(() => {
        if (this.dev) return;

        this.audios.music.muted = false;
        this.audios.music.play();
      }, 2000);

      // this.container.className += " ready";
      this.setReady();
      // this.setChat("pierre");

      this.loop();
    }, Math.max(this.dev ? 0 : 2000 - diffTime, 0));
  }

  loadResources() {
    const resources: any = {};

    // load map

    this.loadModel("office.glb", resources, "map");

    // load views

    const viewKeys = ["front", "secret"];
    const views: any = {};

    for (const name of viewKeys) {
      const view: any = {
        background: this.loadTexture(name + "-background.png"),
        objects: this.loadTexture(name + "-objects.png"),
        water: this.loadTexture(name + "-water.png"),
        waterMask: this.loadTexture(name + "-water-mask.png"),
      };

      this.loadModel(name + ".glb", view, "model");

      views[name] = view;
    }

    resources.views = views;

    // various

    const waterNormals = this.loadTexture("waternormals.jpg");
    waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping;

    resources.waterNormals = waterNormals;

    // load people

    const people: any = {};

    for (const worker of workers) {
      const name = worker.name;
      const datas: any = {
        texture: this.loadTexture(name + "-001.png"),
      };

      this.loadModel(name + "-001.glb", datas, "model");

      people[name] = datas;
    }

    resources.people = people;

    // player

    const player = {
      texture: this.loadTexture("tv.png"),
      eye: this.loadTexture("eye.png"),
    };

    this.loadModel("tv.glb", player, "model");

    resources.player = player;

    this.resources = resources;
  }

  checkResources() {
    // console.log(this.resourcesCount, this.resourcesLoaded);
    if (this.resourcesCount === this.resourcesLoaded) this.run();
  }

  loadTexture(path: string): THREE.Texture {
    const textureLoader = new THREE.TextureLoader();

    this.resourcesCount++;

    return textureLoader.load(path, (texture) => {
      // The actual texture is returned in the event.content
      texture.minFilter = THREE.LinearFilter;

      this.resourcesLoaded++;
      this.checkResources();
    });
  }

  onWindowResize() {
    // this.currentMap.camera.aspect = window.innerWidth / window.innerHeight;
    const container = this.container;
    let width = container.clientWidth;
    const height = container.clientHeight;

    if (height > width) {
      width = height * 1.777;

      window.setTimeout(() => {
        window.scrollTo(width / 2 - window.screen.width / 2, 0);
        // console.log(width / 2 - container.clientWidth / 2);
      }, 500);
    }

    this.size.x = width;
    this.size.y = height;

    if (this.renderer) this.renderer.setSize(this.size.x, this.size.y);
    if (this.place) this.place.view.resize();

    const box = this.chatContainer.getBoundingClientRect();

    this.chatSize = {
      x: box.width,
      y: box.height,
    };
    this.chatPosition = {
      x: box.left,
      y: box.top,
    };
  }

  setAudios(elements: Array<any>) {
    this.audios = elements;
  }

  addLabel(position: THREE.Vector3, content: any) {
    const map = this.currentMap;
    const camera = map.camera;

    const label = document.createElement("div");
    label.className = "label";
    label.innerHTML = content;

    var width = this.size.x,
      height = this.size.y;
    var widthHalf = width / 2,
      heightHalf = height / 2;

    camera.updateMatrixWorld();

    position = position.clone();
    position.project(camera);
    position.x = position.x * widthHalf + widthHalf;
    position.y = -(position.y * heightHalf) + heightHalf;

    label.style.left = position.x + "px";
    label.style.top = position.y + "px";

    window.document.body.appendChild(label);
  }

  setChat(name: string) {
    const scene = new THREE.Scene();
    const worker = new Worker(this.place);
    worker.build(scene, name, true);

    // worker.armature.position.set(0, 0, 0);

    const size = this.chatSize;

    // const box = new THREE.BoxGeometry(0.1, 0.1);
    // const boxMaterial = new THREE.MeshBasicMaterial({ side: THREE.DoubleSide });
    // const test = new THREE.Mesh(box, boxMaterial);
    // scene.add(test);

    scene.add(new THREE.AmbientLight(0xffffff, 0.6));
    const light = new THREE.SpotLight(0xffffff, 0.4);
    const lightMove = new THREE.Vector3(3, 0, 2);
    lightMove.applyQuaternion(worker.armature.quaternion);

    light.position.add(lightMove);

    scene.add(light);

    this.chat = scene;
    this.chatCamera = new THREE.PerspectiveCamera(
      35,
      size.x / size.y,
      0.01,
      10
    );

    scene.add(this.chatCamera);

    const cameraMove = new THREE.Vector3(1.0, 0.0, 0.2);
    cameraMove.applyAxisAngle(
      new THREE.Vector3(0, 0, 1),
      worker.armature.rotation.z
    );

    this.chatCamera.position.add(cameraMove).add(worker.armature.position);
    this.chatCamera.up.set(0, 0, 1);
    this.chatCamera.lookAt(
      worker.armature.position.clone().add(new THREE.Vector3(0, 0, 0.05))
    );
    // this.chatCamera.rotation.z -= Math.PI / 2;
    // this.chatCamera.quaternion.copy(worker.armature.quaternion.inverse());

    this.setters.setChat(name);

    // new OrbitControls(this.chatCamera, this.place.game.renderer.domElement);
  }

  closeChat() {
    this.chat = null;
  }

  setReady() {
    this.setters.setReady(true);
  }

  addHelper(
    origin = new THREE.Vector3(0, 0, 0),
    direction: THREE.Vector3 | null = null,
    scene: THREE.Scene | null = null,
    color: number = 0x00ff00
  ) {
    const map = this.currentMap;

    if (!scene) scene = map.scenes.foreground;
    // if (!scene) scene = this.currentMap.scenes.background;

    if (!scene) {
      throw new Error("No scene to add helper");
    }

    if (direction) {
      var arrowHelper = new THREE.ArrowHelper(
        direction.clone().normalize(),
        origin.clone(),
        direction.length(),
        0xff0000
      );
      scene.add(arrowHelper);
    } else {
      const size = 0.25;
      const geometry = new THREE.BoxGeometry(size, size, size);
      const material = new THREE.MeshPhongMaterial({ color });
      const mesh = new THREE.Mesh(geometry, material);
      mesh.position.copy(origin);
      // mesh.rotation.y = Math.PI / 4;
      // console.log(scene);
      scene.add(mesh);
      // console.log(mesh.position);
    }
  }

  loop() {
    const game = this;
    let previousTime = 0;

    function animate(time: number | undefined) {
      const dt = time ? time - previousTime : 0;
      if (time) previousTime = time;

      requestAnimationFrame(animate);
      game.animate(time, dt);

      if (time && game.currentMap) game.currentMap.render(time);
      if (game.chat) {
        const renderer = game.renderer;
        const popupSize = game.chatSize;
        // console.log(document.body.scrollLeft);
        var left = Math.floor(game.chatPosition.x + window.pageXOffset);
        var bottom = Math.floor(
          game.size.y - game.chatPosition.y - popupSize.y
        );
        var width = Math.floor(popupSize.x);
        var height = Math.floor(popupSize.y);

        // console.log(left, bottom, width, height);

        renderer.autoClear = false;
        renderer.clearDepth();
        renderer.setViewport(left, bottom, width, height);
        renderer.setScissor(left, bottom, width, height);
        renderer.setScissorTest(true);
        renderer.setClearColor(0x000000);

        game.renderer.render(game.chat, game.chatCamera);
        // game.renderer.render(
        //   game.currentMap.scenes.background,
        //   game.chatCamera
        // );
      }
    }

    animate(undefined);
  }

  animate(time: number | undefined, dt: number) {
    const length = this.animations.length;
    const end: Array<number> = [];

    for (let key = 0; key < length; key++) {
      if (!this.animations[key][1](time, dt)) end.push(key);
    }

    if (end.length) {
      this.animations = this.animations.filter(
        (animation, key) => end.indexOf(key) === -1
      );
    }
  }

  selectPlace(event: any) {
    event.preventDefault();

    var mouse3D = new THREE.Vector3(
      (event.pageX / this.size.x) * 2 - 1,
      -(event.pageY / this.size.y) * 2 + 1,
      10
    );
    var raycaster = new THREE.Raycaster();
    // console.log("select");
    raycaster.setFromCamera(mouse3D, this.currentMap.camera);
    var intersects = raycaster.intersectObjects(this.selectables);
    // console.log(this.selectables, intersects);
    let object;

    if (intersects.length > 0) {
      // console.log(intersects[0].faceIndex);
      object = intersects[intersects.length - 1].object;
      object.userData.goto(intersects[0]);
    }

    return object;
  }

  addAnimation(animation: any) {
    // const id = new Date().getTime();
    const id = window.performance.now();
    this.animations.push([id, animation]);

    return id;
  }

  removeAnimation(id: number) {
    const index = this.animations.findIndex((animation) => animation[0] === id);
    if (index !== -1) this.animations.splice(index, 1);
  }

  loadModel(path: string, container: any, name: string) {
    const gltfLoader = new GLTFLoader();

    this.resourcesCount++;

    gltfLoader.load(
      path,
      (gltf: any) => {
        const objects: any = {};
        gltf.scene.children.map((child: any) => (objects[child.name] = child));
        // console.log(path, gltf, objects);

        container[name] = {
          objects,
          gltf,
        };

        this.resourcesLoaded++;
        this.checkResources();
      },
      undefined,
      (error: any) => {
        console.error("Error", error);
      }
    );
  }
}
