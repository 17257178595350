import * as THREE from "three";

import Game from "./Game";
import GraphRoad from "../astar/GraphRoad";
import grid from "../grid.json";
// import grid from "./place-001-grid.json";

export default class Map {
  game: Game;
  mesh!: THREE.Mesh;
  size: any = {
    x: 0,
    y: 0,
  };
  cameraSize: any = {
    x: 0,
    y: 0,
  };
  scene!: THREE.Scene;
  camera!: THREE.OrthographicCamera;
  models: any;
  scenes: any;
  grid!: GraphRoad;

  constructor(game: Game) {
    this.game = game;
    this.size = game.size;
  }

  async build() {
    this.scenes = {};
    this.scenes.background = new THREE.Scene();
    this.scenes.foreground = new THREE.Scene();

    const file: any = await this.game.resources.map;
    this.models = file.objects;

    this.grid = this.loadGrid(grid);
  }

  loadGrid(options: any = null) {
    const vertices = options.vertices;
    const verticesLength = vertices.length;
    const nodes = [];

    for (let key = 0; key < verticesLength; key += 3) {
      nodes.push({
        id: key / 3,
        position: {
          x: vertices[key + 0],
          y: vertices[key + 1],
          z: vertices[key + 2],
        },
      });
    }

    const edges = options.edges;
    const edgesLength = edges.length;
    const paths = [];

    for (let key = 0; key < edgesLength; key += 2) {
      paths.push({
        id: key / 2,
        source: edges[key + 0],
        target: edges[key + 1],
      });
    }

    const grid: GraphRoad = new GraphRoad(
      this,
      {
        nodes,
        paths: paths.filter((path) => path.source !== undefined),
      },
      new THREE.Vector3()
    );

    return grid;
  }

  render(time: number) {
    const camera = this.camera;

    if (!camera) return;

    const renderer = this.game.renderer;

    var left = Math.floor(0);
    var bottom = Math.floor(0);
    var width = Math.floor(this.size.x);
    var height = Math.floor(this.size.y);

    renderer.setViewport(left, bottom, width, height);
    renderer.setScissor(left, bottom, width, height);
    renderer.setScissorTest(true);
    renderer.setClearColor(0x000000);

    this.game.renderer.render(this.scenes.background, camera);
  }
}
