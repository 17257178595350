import * as THREE from "three";

const WaterShader = {
  uniforms: {
    backgroundColor: {
      value: new THREE.Vector3(0.144, 0.558, 0.687),
    },
    tDiffuse: {
      value: null,
    },
    normalSampler: {
      value: null,
    },
    time: {
      value: 0,
    },
    noiseScale: {
      value: 0.04,
    },
  },
  vertexShader: /* glsl */ `
        varying vec2 vUv;
        varying float noise;
        uniform float time;


        void main() 
        {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }
      `,
  fragmentShader: /* glsl */ `
        varying vec2 vUv;
        // varying float noise;

        uniform sampler2D tDiffuse;
        uniform sampler2D tMask;
        uniform sampler2D normalSampler;
        uniform vec3 backgroundColor;

        uniform float time;
        uniform float noiseScale;

        vec3 getNoise(in vec2 uv, float noiseScale, float time)
        {
          vec2 uv0 = uv / (103.0 * noiseScale) + vec2(time / 17.0, time / 29.0);
          vec4 noise = texture2D(normalSampler, uv0);
          return noise.xyz;
          // return noise.xyz * 0.5 - 1.0;
        }

        float rand(vec2 co){
          return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
        }
        void main()
        {
          vec4 mask = texture2D( tMask, vUv);

          vec3 noise = getNoise(vUv.xy, noiseScale / 2.1, time * 3.0) * 0.5;
          vec3 noise2 = getNoise(vUv.xy, noiseScale / 2.1, -time * 3.0 - 50.0) * 0.5;

          float maskValue = max(1.0 - mask.a * 3.0, 0.01) * 0.2;

          vec4 color = texture2D( tDiffuse, vUv + (noise.xy * noise2.xy - vec2(0.06, 0.06)) * vec2(maskValue * 2.1, maskValue * 2.1));
          // vec4 color = texture2D( tDiffuse, vUv );

          color = mix(color, LinearToGamma( vec4(backgroundColor, 1.0), float( GAMMA_FACTOR ) ), 1.0 - color.a * 0.5);

          vec3 noise3 = getNoise(vUv.xy, noiseScale / 5.0, time * 5.0);
          vec3 noise4 = getNoise(vUv.xy, noiseScale / 5.0, -time * 5.0 - 50.0);

          float combine = noise3.y - noise4.y;
          float combineMax = 0.4 - mask.a * 0.33;
          color.w = combine > -combineMax && combine < combineMax ? 1.0 : 0.0;
          
          color.w *= noise.y * 10.0 + 2.2;
          color.w *= noise2.y * 10.0 + 2.2;

          // gl_FragColor = mask;
          gl_FragColor = color;
        } 
    `,
  // fragmentShader: document.getElementById('fragment-shader').textContent
};

export default WaterShader;
