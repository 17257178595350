import * as THREE from "three";
import Graph from "./Graph";
import NodeRoad from "./NodeRoad";

export default class Segment {
  grid: Graph;
  source: NodeRoad;
  target: NodeRoad;
  vector: THREE.Vector3;
  id: string;

  constructor(grid: any, id: string, source: NodeRoad, target: NodeRoad) {
    this.grid = grid;
    this.source = source;
    this.target = target;
    this.id = id;

    const vector = this.target.vector.clone().sub(this.source.vector);
    this.vector = vector;
  }
}
