import { DatasNode, DatasPath } from "../game.specs";
import Node from "./Node";
import NodeRoad from "./NodeRoad";
import Segment from "./Segment";
import Map from "../components/Map";
import Graph from "./Graph";

export interface Adjacent {
  node: Node;
  path: string | null;
  direction: boolean | null;
}

export default class GraphRoad extends Graph {
  paths: Array<Segment>;
  nodes: Array<NodeRoad>;
  adjacents: Array<Adjacent> = [];

  constructor(
    map: Map,
    datas: any = null,
    offset: any = null,
    size: any = null
  ) {
    super(map, datas, offset, size);

    this.nodes = this.loadNodes(datas.nodes);
    this.paths = this.loadPaths(datas.paths);
  }

  loadNodes(datas: Array<DatasNode> | null = null): Array<NodeRoad> {
    return datas ? datas.map((node) => new NodeRoad(this, node)) : [];
  }

  loadPaths(datas: Array<DatasPath>) {
    const adjacents = this.nodes.reduce((previous: any, current: any): any => {
      previous[current.id] = [];
      return previous;
    }, {});
    const nodes = this.nodes.reduce((previous: any, current: any): any => {
      previous[current.id] = current;
      return previous;
    }, {});

    const segments = datas.map((path) => {
      const segment = new Segment(
        this,
        path.id,
        nodes[path.source],
        nodes[path.target]
      );

      return segment;
    });

    segments.map((segment) => {
      if (
        !segment.source.adjacents.find(
          (adjacent: any) => adjacent.node === segment.target
        )
      ) {
        segment.source.adjacents.push({
          node: segment.target,
          path: segment,
          direction: true,
        });
      }
      if (
        !segment.target.adjacents.find(
          (adjacent: any) => adjacent.node.id === segment.source
        )
      ) {
        segment.target.adjacents.push({
          node: segment.source,
          path: segment,
          direction: true,
        });
      }
    });

    return segments;
  }

  toJSON() {
    return {
      paths: this.paths,
      nodes: this.nodes,
    };
  }
}
