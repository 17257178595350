export default {
  pierre: [
    {
      content: "Hello !",
      target: "salut_1",
    },
    {
      id: "salut_1",
      choices: [
        {
          content: "Salut !",
          target: "salut_2",
        },
        {
          content: "Où sommes-nous ?",
          target: "lieu_1",
        },
      ],
    },
    {
      id: "salut_2",
      content: "Comment va ?",
      target: "salut_3",
    },
    {
      id: "salut_3",
      choices: [
        {
          content: "ça roule",
          target: "end",
        },
      ],
    },
    {
      id: "lieu_1",
      content:
        "Ceci est une représentation conceptuelle et marketo-virtuelle de l'agence Lemon",
      target: "end",
    },
    {
      choices: [
        {
          content: "Ok",
          target: "end",
        },
        {
          content: "C'est top !",
          target: "end",
        },
      ],
    },
    {
      id: "end",
      content: "Bye",
    },
  ],
  joelle: [
    {
      content: "Salut, je suis Joëlle",
      target: "salut_1",
    },
    {
      id: "salut_1",
      content: "J'aime les kiens",
      target: "salut_2",
    },
    {
      id: "salut_2",
      choices: [
        {
          content: "Salut Joëlle",
          target: "end",
        },
        {
          content: "Yop !",
          target: "salut_3",
        },
      ],
    },
    {
      id: "salut_3",
      content: "Yep",
      target: "end",
    },
    {
      id: "end",
      content: "Bye",
    },
  ],
  aline: [],
  fabio: [],
  rodolphe: [],
};
