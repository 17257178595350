import * as THREE from "three";
import Place from "./Place";

export default class Worker {
  place: Place;
  mesh!: THREE.Mesh;
  armature!: any;
  mixer!: THREE.AnimationMixer;
  clock = new THREE.Clock();
  event: any;
  animation: any;
  animations: any;

  constructor(place: Place) {
    this.place = place;
    this.animations = [];
  }

  async build(scene: THREE.Scene, name: string, clone = false) {
    const resource = this.place.game.resources.people[name];
    const file: any = resource.model;

    const texture = resource.texture;
    texture.flipY = false;

    const mesh = clone
      ? file.objects[name + "001"].clone()
      : file.objects[name + "001"];
    const armature = file.objects[name + "armature"];

    mesh.material.map = texture;
    mesh.material.transparent = true;
    mesh.material.side = THREE.FrontSide;

    if (!clone) scene.add(armature);
    scene.add(mesh);

    this.mesh = mesh;
    this.armature = armature;
    // console.log(armature, file.objects);
    mesh.castShadow = true;

    if (clone) return;
    // mesh.children.map((child: THREE.Object3D) => (child.castShadow = true));

    // const mixer = new THREE.AnimationMixer(file.gltf.scene);
    const mixer = new THREE.AnimationMixer(mesh);

    this.mixer = mixer;

    const animations = file.gltf.animations.reduce(
      (result: any, current: any) => {
        result[current.name] = { action: mixer.clipAction(current) };
        return result;
      },
      {}
    );

    this.animations = {
      wait: animations[name + ".wait"],
    };

    this.animation = this.place.game.addAnimation((time: number) =>
      this.animate(time)
    );

    this.animations.wait.action.weight = 1;
    this.animations.wait.action.play();
  }

  animate(time: number) {
    // this.mesh.rotation.y += 0.01;

    var mixerUpdateDelta = this.clock.getDelta();
    // console.log(mixerUpdateDelta, time);
    this.mixer.update(mixerUpdateDelta);
    // console.log(mixerUpdateDelta);

    return true;
  }
}
