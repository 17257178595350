import * as THREE from "three";
import Place from "./Place";
import WaterShader from "../shaders/WaterShader";
import Water3D from "../shaders/Water3D";
var OrbitControls = require("three-orbit-controls")(THREE);

export default class View {
  place: Place;
  models: any;
  objects: THREE.Group;
  camera!: THREE.OrthographicCamera;
  cameraFrustum: number = 4;

  constructor(place: Place) {
    this.place = place;
    this.objects = new THREE.Group();
    this.objects.visible = false;
  }

  async build(scene: THREE.Scene, name: string) {
    const resources = this.place.game.resources.views[name];

    // load models

    const content: any = resources.model;
    this.models = content.objects;

    // camera

    const camera = new THREE.OrthographicCamera(0, 0, 0, 0, -300, 300);

    const cameraSource: THREE.OrthographicCamera = this.models[name + "camera"];
    this.camera = camera;
    // this.camera = cameraSource;
    this.camera.position.copy(cameraSource.position);
    this.camera.quaternion.copy(cameraSource.quaternion);
    // this.camera.updateMatrix();

    this.cameraFrustum = cameraSource.top / 1.8;

    this.resize();

    // new OrbitControls(camera, this.place.game.renderer.domElement);

    // background

    var texture = resources.background;
    texture.flipY = false;

    var material = new THREE.MeshBasicMaterial({
      map: texture,
      color: 0xffffff,
      transparent: true,
    });

    const background = this.models[name + "background"];

    background.material = material;

    var textureObjects = resources.objects;
    textureObjects.flipY = false;

    var materialObjects = new THREE.MeshBasicMaterial({
      map: textureObjects,
      transparent: true,
    });

    scene.add(background);

    // objects

    const objects = this.models;

    const group = this.objects;

    for (var key in objects) {
      if (key.match(/mask$/)) {
        objects[key].material = materialObjects;
        group.add(objects[key]);
      }

      if (key === "logomask") {
        const logo = objects[key];

        logo.material = logo.material.clone();
        logo.material.opacity = 0.0;

        const animateNoise = (key: number, count: number, opacity: number) => {
          const last = count - 1 === key;

          window.setTimeout(() => {
            logo.material.opacity = opacity;
            window.setTimeout(() => {
              logo.material.opacity = last ? 0.0 : opacity / 1.5;
            }, (200 / count) * 3);
          }, (200 * key * 4) / count);
        };

        const animateLogo = () => {
          const count = 1 + Math.round(Math.random() * 5);
          // const count = 2;
          const opacity = 0.3 + Math.random() * 0.3;
          // console.log(count, opacity);

          for (let key = 0; key < count; key++) {
            animateNoise(key, count, opacity);
          }

          window.setTimeout(animateLogo, 5000 + 10000 * Math.random());
        };

        animateLogo();
      }
    }

    scene.add(group);

    // water

    const water = objects[name + "water"];
    var waterTexture = resources.water;
    var waterMask = resources.waterMask;

    const normals = this.place.game.resources.waterNormals;
    // const normals = new THREE.TextureLoader().load("waternormals-hexa.jpg");

    const waterMaterial = new THREE.ShaderMaterial({
      ...WaterShader,
      uniforms: {
        ...WaterShader.uniforms,
        tDiffuse: { value: waterTexture },
        tMask: { value: waterMask },
        normalSampler: {
          value: normals,
        },
      },
    });

    // const waterMaterialTest = new THREE.MeshBasicMaterial({ color: 0x0000ff });

    water.material = waterMaterial;
    // water.material = waterMaterialTest;

    this.place.game.addAnimation((time: number, dt: number) => {
      waterMaterial.uniforms.time.value = time / 100000;
      return true;
    });

    scene.add(water);
  }

  resize() {
    const camera = this.camera;

    const size = this.place.game.size;
    const w = size.x;
    const h = size.y;

    const aspect = w / h;
    const frustumSize = this.cameraFrustum;

    camera.left = (frustumSize * aspect) / -2;
    camera.right = (frustumSize * aspect) / 2;
    camera.top = frustumSize / 2;
    camera.bottom = frustumSize / -2;

    camera.updateProjectionMatrix();
  }

  show() {
    this.place.map.camera = this.camera;
    this.objects.visible = true;
  }

  hide() {
    this.objects.visible = false;
  }
}
