import * as THREE from "three";

const ScreenShader = {
  uniforms: {
    diffuse: {
      value: null,
    },
    diffuseColor: {
      value: null,
    },
    eye: {
      value: 0,
    },
    blink: {
      value: 0,
    },
    noiseScale: {
      value: 0,
    },
    time: {
      value: 0,
    },
  },
  vertexShader: /* glsl */ `
        varying vec2 vUv;
      	varying vec3 vNormal;

        uniform float time;
        uniform vec4 blink;
        uniform vec4 eye;

        ${THREE.ShaderChunk["skinning_pars_vertex"]}

        void main() 
        {
          ${THREE.ShaderChunk["beginnormal_vertex"]}
          ${THREE.ShaderChunk["skinbase_vertex"]}
          ${THREE.ShaderChunk["skinnormal_vertex"]}
          ${THREE.ShaderChunk["defaultnormal_vertex"]}

          vNormal = normalize( transformedNormal );

          ${THREE.ShaderChunk["begin_vertex"]}
          ${THREE.ShaderChunk["skinning_vertex"]}
          ${THREE.ShaderChunk["project_vertex"]}

          vUv = uv;

          if (blink.z < -0.27) vUv.x += 0.333;
          if (blink.z < -0.45) vUv.x += 0.333;

          // vUv += eye.xy * 0.5;

          // gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }
      `,
  fragmentShader: /* glsl */ `
        varying vec2 vUv;
        varying vec3 vNormal;

        uniform sampler2D diffuse;
        uniform sampler2D normalSampler;
        uniform float noiseScale;
        uniform vec3 diffuseColor;

        uniform float time;

        vec3 getNoise(in vec2 uv, float noiseScale, float time)
        {
          vec2 uv0 = uv / (103.0 * noiseScale) + vec2(time / 17.0, time / 29.0);
          vec2 uv1 = uv / (107.0 * noiseScale) - vec2(time / -19.0, time / 31.0);
          vec2 uv2 = uv / (vec2(8907.0, 9803.0) * noiseScale) + vec2(time / 101.0, time /   97.0);
          vec2 uv3 = uv / (vec2(1091.0, 1027.0) * noiseScale) - vec2(time / 109.0, time / -113.0);
          vec4 noise = texture2D(normalSampler, uv0) +
            texture2D(normalSampler, uv1) +
            texture2D(normalSampler, uv2) +
            texture2D(normalSampler, uv3);
          return noise.xyz * 0.5 - 1.0;
        }

        void main()
        {
          vec3 noise = noiseScale > 0.0 ? getNoise(vUv.xy, noiseScale, time) * 0.3 : vec3(0, 0, 0);
          vec4 color = texture2D( diffuse, vUv + noise.xy);

          // gl_FragColor = mix(vec4(0.0, 0.0, 0.0, 1.0), vec4(1.0, 1.0, 1.0, 1.0), color.a);
          gl_FragColor = mix(vec4(0.0, 0.0, 0.0, 1.0), vec4(diffuseColor, 1.0), color.a);
          // gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0);
        } 
    `,
  // fragmentShader: document.getElementById('fragment-shader').textContent
};

export default ScreenShader;
